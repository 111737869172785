import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'github-markdown-css';
import Editor from '@monaco-editor/react';
import { FaChevronDown, FaChevronRight, FaFolder, FaCheck } from 'react-icons/fa';
import { debounce } from 'lodash';
import useAuth from "../hooks/useAuth";
import { Treebeard } from 'react-treebeard';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function CodingClass() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [sections, setSections] = useState([]);
  const [lessons, setLessons] = useState({});
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [code, setCode] = useState('');
  const [output, setOutput] = useState('');
  const [figurePlotUrl, setFigurePlotUrl] = useState('');
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [isLoadingLessons, setIsLoadingLessons] = useState(true);
  const [openSections, setOpenSections] = useState({});
  const [lessonContentLoading, setLessonContentLoading] = useState(false);
  const [isLoadingDirectory, setIsLoadingDirectory] = useState(true);
  const [treeData, setTreeData] = useState([]);
  const [cursor, setCursor] = useState(null);
  const [courseTierImageUrl, setCourseTierImageUrl] = useState('/characters/novice.jpg');
  const [isVerticalMobile, setIsVerticalMobile] = useState(false);
  const [lessonAnswer, setLessonAnswer] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [expectedAnswer, setExpectedAnswer] = useState('');
  const [quest, setQuest] = useState(null);
  const [questLessonID, setQuestLessonId] = useState('');
  const [questStatus, setQuestStatus] = useState({}); // State to store quest completion status
  const [isLoadingQuestStatus, setIsLoadingQuestStatus] = useState(true);
  const [tierCurrent, setTierCurrent] = useState('');
  const [courseTier, setCourseTier] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
      const isPortrait = window.innerHeight > window.innerWidth;
      setIsVerticalMobile(isMobile && isPortrait);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Workaround: Monkey-patch KeyboardEvent prototype if getModifierState is missing
  useEffect(() => {
    if (typeof KeyboardEvent.prototype.getModifierState !== 'function') {
      KeyboardEvent.prototype.getModifierState = function (keyArg) {
        // Basic implementation, adjust as needed
        if (keyArg === 'Shift' && this.shiftKey) return true;
        // Add other modifier keys (Ctrl, Alt, etc.) if necessary
        return false;
      };
    }
  }, []);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const res = await fetch(`${BASE_URL}courses/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        });

        if (res.status === 401) {
          setAuth(null);
          navigate("/login");
          return;
        }

        const data = await res.json();

        if (data.detail === 'Signature has expired') {
          setAuth(null);
          navigate("/login");
          return;
        }

        setSelectedCourse(data);
        setCourseTier(data.tier);
        setSections(data.sections);

        // Set the course tier image URL
        const tierImageMap = {
          Novice: "/characters/novice.jpg",
          Apprentice: "/characters/apprentice.jpg",
          Adept: "/characters/adept.jpg",
          Veteran: "/characters/veteran.jpg",
          Master: "/characters/master.jpg",
          Legend: "/characters/legend.jpg",
        };

        setCourseTierImageUrl(tierImageMap[auth.tier] || "/characters/novice.jpg");

        // Fetch all lessons for all sections
        const allLessons = {};
        await Promise.all(data.sections.map(async (section) => {
          const res = await fetch(`${BASE_URL}courses/${id}/sections/${section._id}/lessons`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (res.status === 401) {
            setAuth(null);
            navigate("/login");
            return;
          }

          const lessonsData = await res.json();

          if (lessonsData.detail === 'Signature has expired') {
            setAuth(null);
            navigate("/login");
            return;
          }

          allLessons[section._id] = lessonsData;
        }));
        setLessons(allLessons);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoadingLessons(false);
        setIsPending(false);
      }
    };

    fetchCourse();
  }, [id, auth.token, navigate, setAuth]);

  useEffect(() => {
    // Show loading indicator when a new lesson is selected
    setLessonContentLoading(true);
    const timer = setTimeout(() => {
      setLessonContentLoading(false);
    }, 100); // Adjust delay (500ms) as needed

    return () => clearTimeout(timer);
  }, [selectedLesson]);

  
  const handleLessonClick = async (event, lesson) => {
    event.preventDefault();
    // console.log('Lesson clicked:', lesson);
    setSelectedLesson(lesson);

    // Fetch the lesson answer
    try {
      const res = await fetch(`${BASE_URL}courses/${id}/lessons/${lesson._id}/answer`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      });

      if (res.status === 401) {
        setAuth(null);
        navigate("/login");
        return;
      }

      const [bool_quest, data] = await res.json()

      if (data.detail === 'Signature has expired') {
        setAuth(null);
        navigate("/login");
        return;
      }

      setLessonAnswer(data);
      setQuest(bool_quest);
      setQuestLessonId(lesson._id);
    } catch (err) {
      setError(err.message);
    }

  };


  const toggleSection = useCallback(debounce((sectionId) => {
    setOpenSections(prevState => ({
      ...prevState,
      [sectionId]: !prevState[sectionId],
    }));
  }, 300), []);

  const runCode = async () => {
    try {
      // Check if the directory exists and create it if necessary
      const createfolder = await fetch(`${BASE_URL}codeclass/createfolder`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        }
      });

      if (createfolder.status === 401) {
        setAuth(null);
        navigate("/login");
        return;
      }

      const foldercreated = await createfolder.json();

      if (foldercreated.detail === 'Signature has expired') {
        setAuth(null);
        navigate("/login");
        return;
      }

      // Wait for the folder to be created before running the code
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Run the code
      const res = await fetch(`${BASE_URL}codeclass/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({ code }),
      });

      if (res.status === 401) {
        setAuth(null);
        navigate("/login");
        return;
      }

      const data = await res.json();

      if (data.detail === 'Signature has expired') {
        setAuth(null);
        navigate("/login");
        return;
      }

      setOutput(data.output);

      if (data.figurePlotUrl) {
        setFigurePlotUrl(data.figurePlotUrl);
      }

      if (quest) {
        if (data.output === lessonAnswer) {
          setResultMessage('Your quest is completed!');
          setExpectedAnswer('');
          const updateQuestCompleted = await fetch(`${BASE_URL}courses/quest/${questLessonID}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`,
            },
          });

          if (updateQuestCompleted.status === 401) {
            setAuth(null);
            navigate("/login");
            return;
          }

          const questCompleted = await updateQuestCompleted.json();

          if (questCompleted.detail === 'Signature has expired') {
            setAuth(null);
            navigate("/login");
            return;
          }


        } else {
          setResultMessage('Output does not match the Quest answer');
          setExpectedAnswer(lessonAnswer);
        }
      } else {
        setResultMessage('');
        setExpectedAnswer('');
      }
    } catch (err) {
      setError('Error running code');
    }
  };

  useEffect(() => {
    setCode('print("Hello, world !")');
  }, []);

  useEffect(() => {
    let isMounted = true;
    let previousData = null; // Store the previously fetched data

    const fetchDirectoryItems = async () => {
      try {
        const response = await fetch(`${BASE_URL}codeclass/listdirectory`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Compare the new data with the previous data
        if (JSON.stringify(data.items) !== JSON.stringify(previousData)) {
          previousData = data.items; // Update previousData

          if (isMounted) {
            setTreeData(transformDirectoryItems(data.items, treeData));
          }
        }
      } catch (error) {
        console.error('Error fetching directory items:', error);
        if (isMounted) {
          setTreeData([]);
        }
      } finally {
        if (isMounted) {
          setIsLoadingDirectory(false);
        }
      }
    };

    fetchDirectoryItems();
    const intervalId = setInterval(fetchDirectoryItems, 5000);

    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, [auth.token, navigate, setAuth]);

  const transformDirectoryItems = (items, existingTreeData = []) => {
    return items.map((item) => {
      const existingNode = existingTreeData.find(node => node.name === item.name);
      return {
        name: item.name,
        toggled: existingNode ? existingNode.toggled : false, // Preserve toggled state
        children: item.is_dir ? transformDirectoryItems(item.children || [], existingNode?.children || []) : undefined,
      };
    });
  };


  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);


    // Update treeData only when necessary to avoid triggering useEffect unnecessarily
    setTreeData(prevTreeData => {
      const newTreeData = [...prevTreeData];
      const updateNodeToggledState = (nodes, targetNode, newToggledState) => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i] === targetNode) {
            nodes[i].toggled = newToggledState;
            return true;
          }
          if (nodes[i].children && updateNodeToggledState(nodes[i].children, targetNode, newToggledState)) {
            return true;
          }
        }
        return false;
      };
      updateNodeToggledState(newTreeData, node, toggled);
      return newTreeData;
    });
  };

  useEffect(() => {
    // Open all sections when the component mounts
    const initialOpenSections = {};
    sections.forEach(section => {
      initialOpenSections[section._id] = true;
    });
    setOpenSections(initialOpenSections);
  }, [sections]);


  useEffect(() => {
    const fetchQuestStatus = async () => {
      try {
        const res = await fetch(`${BASE_URL}courses/quests/status`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        });

        if (res.status === 401) {
          setAuth(null);
          navigate("/login");
          return;
        }

        const data = await res.json();

        if (data.detail === 'Signature has expired') {
          setAuth(null);
          navigate("/login");
          return;
        }
        // Assuming the response is an object with lesson IDs as keys and boolean completion status as values
        setTierCurrent(data[0]);
        setQuestStatus(data[1]); 
      } catch (err) {
        setError(err.message);
      }
    };

    fetchQuestStatus();
    setIsLoadingQuestStatus(false);
  }, [auth.token, navigate, setAuth]); 


  const Section = React.memo(({ section, openSections, toggleSection, lessons, handleLessonClick, isLoadingLessons, questStatus, tierCurrent, courseTier }) => {
    const tierRanking = ["Novice", "Apprentice", "Adept", "Veteran", "Master", "Legend"]; // Define the tier ranking
    
    return (
      <div key={section._id} style={{ position: 'relative', zIndex: 1 }}>
        <h4 className="text-white cursor-pointer flex items-center" onClick={() => toggleSection(section._id)}>
          {openSections[section._id] ? <FaChevronDown className="mr-2" /> : <FaChevronRight className="mr-2" />}
          {section.title} 
        </h4> 
  
        {openSections[section._id] && (
        isLoadingLessons ? (
          <p>Loading lessons...</p>
        ) : (
          <ul className="list-none pl-4">
            {lessons[section._id]?.map((lesson) => {
              const isCompleted = 
                (questStatus[lesson._id]) || // Check if the lesson is completed
                (tierRanking.indexOf(tierCurrent) > tierRanking.indexOf(courseTier)); // Check if the course tier is higher than the user's current tier
                
              return (
                <li key={lesson.id} className="mb-2">
                  <a
                    href="#"
                    onClick={(event) => handleLessonClick(event, lesson)}
                    className="text-blue-500 hover:underline flex items-center transition-colors duration-200"
                    style={{ pointerEvents: 'auto' }}
                  >
                    {isCompleted ? ( // Use the isCompleted variable
                      <FaCheck className="mr-2 text-green-500" />
                    ) : (
                      <span className="mr-2 text-gray-400">&#x2022;</span>
                    )}
                    <span className="font-medium">{lesson.title}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        )
      )}
    </div>
  );
});

  return (
    <div>
      {isVerticalMobile && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg text-center">
            <p className="text-lg mb-4">Please rotate your device to landscape mode for a better experience. Note that this app is designed for desktop use but not mobile device.</p>
            <svg
              className="w-16 h-16 mx-auto animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>

      )}
      <div className="flex flex-col h-screen bg-black text-white">
        <div className="flex h-1/2">
          <div className="w-1/6 p-4 border-r border-gray-700 overflow-y-auto">
            {selectedCourse && (
              <div>
                <h3 className="text-lg mb-2">{selectedCourse.title}</h3>
                {sections.map((section) => (
                  <Section
                    key={section._id}
                    section={section}
                    openSections={openSections}
                    toggleSection={toggleSection}
                    lessons={lessons}
                    handleLessonClick={handleLessonClick}
                    isLoadingLessons={isLoadingLessons}
                    questStatus={questStatus}
                    tierCurrent={tierCurrent}
                    courseTier={courseTier}
                  />
                ))}
              </div>
            )}
          </div>
          {selectedLesson && (
            <div className="w-2/5 p-4 mx-auto overflow-y-auto bg-black text-white">
              {lessonContentLoading ? (
                <div>Loading lesson...</div>
              ) : (
                <ReactMarkdown
                  className="markdown-body bg-black text-white"
                  remarkPlugins={[remarkGfm]}
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '');
                      return !inline && match ? (
                        <SyntaxHighlighter
                          style={vscDarkPlus}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                    ul({ node, ...props }) {
                      return <ul className="list-disc list-inside" {...props} />;
                    },
                    ol({ node, ...props }) {
                      return <ol className="list-decimal list-inside" {...props} />;
                    },
                    li({ node, ...props }) {
                      return <li {...props} />;
                    },
                    table({ node, ...props }) {
                      return (
                        <table className="table-auto w-full bg-white text-black" {...props} />
                      );
                    },
                    th({ node, ...props }) {
                      return (
                        <th className="border px-4 py-2 bg-gray-200" {...props} />
                      );
                    },
                    td({ node, ...props }) {
                      return (
                        <td className="border px-4 py-2" {...props} />
                      );
                    },
                  }}
                  style={{ backgroundColor: 'black', color: 'white' }}
                >
                  {selectedLesson.content}
                </ReactMarkdown>
              )}
            </div>
          )}
          <div className={`p-4 ${selectedLesson ? 'w-2/5 ml-auto' : 'w-5/6'}`}>
            <Editor
              height="100%"
              width="100%"
              defaultLanguage="python"
              value={code}
              onChange={setCode}
              theme="vs-dark"
              options={{
                fontSize: 16,
                lineNumbers: 'on',
                minimap: { enabled: false },
              }}
            />
          </div>
        </div>
        <div className="flex h-1/2">
          <div className="w-1/6 p-4 border-r border-gray-700 h-full overflow-y-auto">
            <h3 className="text-lg mb-2">Working Directory</h3>
            {isLoadingDirectory ? (
              <div>Loading directory items...</div>
            ) : treeData.length > 0 ? (
              <Treebeard data={treeData} onToggle={onToggle} />
            ) : (
              <div>
                < FaFolder className="w-full h-full" />
              </div>
            )}
          </div>
          <div className="w-3/6 p-4 overflow-y-auto">
            <h3 className="text-lg mb-2">Output</h3>
            <button
              onClick={runCode}
              className="bg-green-500 hover:bg-green-600 text-white font-medium py-1 px-2 rounded mt-2"
            >
              Run Code
            </button>
            {resultMessage && (
              <div className={`mt-4 p-4 rounded text-lg font-bold text-center ${resultMessage.includes('completed') ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                {resultMessage}
              </div>
            )}
            {expectedAnswer && (
              <div className="mt-4 p-4 rounded text-lg font-bold text-center bg-yellow-500 text-white" style={{ whiteSpace: 'pre-wrap' }}>
                Expected Answer: <br /> {expectedAnswer}
              </div>
            )}
            <pre className="bg-gray-800 p-2 rounded">{output}</pre>
          </div>

          <div className="w-2/6 p-4 border-l border-gray-700 h-full">
            {figurePlotUrl ? (
              <img src={figurePlotUrl} alt="Figure Plot" className="w-full h-full object-contain" />
            ) : (
              <img src={courseTierImageUrl} alt="Default Figure Plot" className="w-full h-full object-contain" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


export default CodingClass;

